/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
  }

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  &.scrolled {
  }

  &-lang {
    background: var(--light-harvest);
    width: 100%;
    padding: 7px 0;
  }

  .logo {
    height: 100%;
    width: ac(176px, 100px, 375);
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 53.96%;
    }

    &:hover {
      transform: scale(1.06);
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: left;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    min-height: ac(96px, 56px);

    &::before {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      background: var(--white);
      border-top: 1px solid var(--light-gray);
      border-bottom: 1px solid var(--light-gray);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 0;
      pointer-events: none;
    }

    &-controls {
      display: flex;
      position: relative;
      z-index: 2;
      align-items: center;
      gap: ac(24px, 14px, 1200);
    }
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;
    flex-grow: 1;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: ac(34.2px, 15px, 1200);
    padding-left: ac(40px, 20px, 1200);
  }

  .menu-item {
    &.dropdown {
      position: relative;
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        position: static;
      }
      ul {
        .menu-link {
          position: relative;
        }
      }
    }

    &:hover {
      @mixin mobile-menu-end-point {
        .menu-link:after {
          //transform: rotateX(180deg);
        }

        .dropdown-menu {
          display: block;
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: 16px;
    font-family: var(--font-main);
    font-weight: 600;
    color: var(--black);
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 6.5px 0 6.5px;
    line-height: 1.12;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 1px;
      background: var(--mid-gray);
      transition: all 0.3s ease;
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
        background: var(--primary-green);
      }
    }

    @media (max-width: 1230px) {
      @media (min-width: 993px) {
        font-size: 14px;
      }
    }
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    & > .menu-link {
      padding-right: 0;
      position: static;
    }
    &-btn {
      font-size: 12px;
      padding-left: 8.9px;
      padding-right: 5.6px;
      padding-top: ac(4px, 2px);
      cursor: pointer;
      i {
        display: inline-block;
      }
      &.active {
        background: var(--primary-green);
        color: var(--white);
        i {
          transform: rotateX(180deg) translateY(17%);
        }
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    border-radius: 10px;
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }

    @mixin mobile-menu-end-point {
      background: var(--white);
      padding: 10px 10px 10px 15px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .nav-bg-layer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 75%;
    opacity: 0.3;
    display: none;
    img {
      width: 100%;
      z-index: 0;
      height: auto;
    }
  }
}

.lang-select-wr {
  display: flex;
  justify-content: flex-end;
  gap: 1px;
  align-items: center;

  .lang-icons {
    position: relative;
    overflow: hidden;
    border: 1px solid var(--light-gray);
    border-radius: 50%;
    width: 28px;
    flex-shrink: 0;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      border-color: var(--primary-green);
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      object-fit: cover;
      transition: 0.3s;
      border-radius: 50%;
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    position: fixed;
    .nav-bg-layer {
      display: block;
    }
    .burger,
    .menu-toggle {
      display: flex;
    }
    .dropdown-toggle-btn {
      width: 30px;
      height: 30px;
      display: flex;
      padding: 2px 0 0;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--primary-green);
      margin-left: 15px;
      &:hover {
        background: var(--primary-green);
        color: var(--white);
      }
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: calc(var(--vh, 1vh) * 100);
      min-width: 320px;
      padding-top: 180px;
      background: var(--light-bg);
      padding-right: 5px;
      padding-bottom: 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;
      z-index: -1;

      @media screen and (max-height: 730px) {
        padding-top: 140px;
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 20px;
        padding-right: 15px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .menu-link {
        padding: 0;
        font-size: 20px;
        font-weight: 500;
        margin-left: 30px;
        position: relative;
        width: fit-content;
      }

      .menu-item {
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid var(--light-gray);
        display: flex;
        flex-direction: column;

        &:last-of-type {
          border-bottom: none;
          margin-bottom: 70px;

          @media screen and (max-height: 730px) {
            margin-bottom: 28px;
          }
        }
      }

      @mixin max-sm {
        width: 100%;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 80px;
          width: calc(100% - 25.6vw);
          background: var(--cl-light-grey);
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
      padding-top: 20px;
      padding-bottom: 10px;
      padding-right: 20px;
      align-items: flex-end;
      width: fit-content;
      min-width: revert;
      li {
        padding: 5px 0;

        width: fit-content;
      }
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid var(--primary-green);
  padding: 15px 14px;
  cursor: pointer;
  transition: transform 0.3s;
  z-index: 5;
  margin-left: 20px;
  background: var(--white);

  div {
    height: 2px;
    background: var(--primary-green);
    width: 26px;
    transition: transform 0.3s, opacity 0.3s;
    border-radius: 5px;

    &:nth-of-type(1) {
      transform-origin: left top;
    }

    &:nth-of-type(2) {
      transform-origin: left center;
    }

    &:nth-of-type(3) {
      transform-origin: left bottom;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &.active {
    div {
      &:nth-of-type(1) {
        transform: rotate(42deg) translate(6%, -226%);
      }

      &:nth-of-type(2) {
        transform: translateX(-100%);
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(6%, 280%);
      }
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* end of HEADER COMPONENTS */

@media (max-width: 530px) {
  .header .navbar-controls {
    .btn {
      display: none;
    }
  }
}
