.hero-delivery{
    padding: 64px 0 35px;
    .title{
        font-size: ac(64px, 33px);
        line-height: 1.125;
        margin-bottom: 32px;
    }
    &-wrapper{
        display: flex;
        gap: ac(48px, 25px);
    }
    &-thumbnail{
        position: relative;
        overflow: hidden;
        background: var(--light-harvest);
        width: ac(628px, 300px);
        max-width: 100%;
        flex-shrink: 0;
        border: 1px solid #D0D1D3;
        border-radius: 12px;
        align-self: flex-start;
        &::before{
            content: '';
            display:block;
            padding-top: 71.97%;
        }
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 85%;
            height: 85%;
            object-fit: contain;
            transition: 0.3s;
        }
    }
    &-options{
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: 1.444;

        h2{
            font-size: ac(44px, 28px);
            margin-bottom: ac(44px, 25px);
        }
        h3{
            border-bottom: 1px solid var(--light-gray);
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: ac(24px, 20px);
            font-weight: 600;
            padding-bottom: 13px;
            margin-bottom: 13px;
            margin-top: ac(35px, 25px);
            font-family: var(--font-main);

            span{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 42px;
                width: fit-content;
                min-width: 42px;
                padding: 10px 14px 5px;
                background: var(--light-harvest);
                border-radius: 6px;
                border: 1px solid var(--secondary-green);
                color: var(--primary-green);

                line-height: 1;
                font-size: ac(20px, 18px);
                transform: translateY(-5px);
            }
        }
        p{
            margin-bottom: 12px;
        }
        ul{
            margin-top: 18px;
            border-top: 1px solid var(--light-gray);
            padding: 19px 0 39px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            font-weight: 500;
            b{
                font-size: ac(20px, 18px);
                font-weight: 600;
            }
        }
    }
}

@media (max-width: 750px) {
    .hero-delivery-wrapper{
        flex-direction: column;
    }
}