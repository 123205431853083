.hero-order {
  padding: 56px 0 ac(82px, 50px);

  .title {
    font-size: ac(44px, 32px);
    line-height: 1.1136;
    margin-bottom: 48px;
  }
  .back-link {
    margin-bottom: ac(42px, 35px);
  }
  .bg-layer {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    z-index: -1;
    width: 462px;
    max-width: 38%;
    transform: translate(-10.1%, 36%) scale(0.8);
    opacity: 0;
    transition: 0.3s 0.3s;

    img {
      width: 100%;
      height: auto;
      opacity: 0.2;
    }
    &.animated {
      transform: translate(-10.1%, 36%);
      opacity: 1;
    }
  }
}

.confirm {
  display: flex;
  gap: ac(48px, 25px);

  &-steps {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
    width: ac(696px, 375px);
  }

  &-item {
    border-radius: 16px;
    border: 1px solid var(--light-gray);
    padding: ac(32px, 12px);
    background: var(--light-harvest);

    &-header {
      display: flex;
      align-items: center;
      gap: ac(20px, 10px);
      margin-bottom: 17px;

      transition: 0.3s;

      i.icon-chevron-up {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        margin-top: ac(-10px, -5px);
        padding: 12px 12px 13px;
        cursor: pointer;
        background: transparent;
        transition: 0.3s;
        border-radius: 50%;
        overflow: hidden;
      }

      i.icon-chevron-up {
        &:hover {
          color: var(--white);
          background: var(--secondary-green);
        }
      }

      &:not(.active) {
        .icon-chevron-up {
          transform: rotateX(180deg);
        }
      }
    }

    &-number {
      flex-shrink: 0;
      min-width: ac(48px, 33px);
      min-height: ac(48px, 33px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 5px 2px;
      line-height: 1;
      border: 1px solid var(--secondary-green);
      border-radius: 6px;
      font-size: ac(24px, 20px);
      font-weight: 600;
      color: var(--primary-green);
      margin-top: ac(-9px, -4px);
    }

    &-text {
      flex: 1;

      div {
        font-size: ac(24px, 20px);
        font-weight: 600;
        line-height: 1.16;
        margin-bottom: 2px;

        b {
          color: var(--red);
        }
      }

      span {
        color: var(--red);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.41;
      }
    }

    &-form {
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 12px;
      }
    }

    &-delivery-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-delivery {
      border: 1px solid var(--light-gray);
      background: var(--white);
      padding: ac(24px, 15px) ac(24px, 15px) ac(32px, 15px);
      border-radius: 8px;

      .radio-wr {
        margin-bottom: 23px;
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 17px 12px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: -2px;
        margin-bottom: -10px;

        li {
          color: var(--dark-gray);
          padding-left: 19px;
          position: relative;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;

          &:before {
            content: '';
            display: block;
            width: 2.5px;
            height: 2.5px;
            border-radius: 50%;
            background: var(--dark-gray);
            position: absolute;
            top: ac(8.9px, 10.3px);
            left: 6px;
          }
        }
      }
    }
  }
  .meta-select {
    .choices__placeholder {
      color: var(--mid-gray);
    }
    & > {
      label {
        b {
          color: var(--primary-green);
        }
        color: var(--black);
      }
    }

    .choices[data-type*='select-one']:after,
    .choices[data-type*='select-multiple']:after {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8135 1.08689L7.4502 7.4502C7.39111 7.50937 7.32093 7.5563 7.24368 7.58833C7.16643 7.62035 7.08362 7.63683 7 7.63683C6.91638 7.63683 6.83357 7.62035 6.75632 7.58833C6.67907 7.5563 6.60889 7.50937 6.54979 7.4502L0.186481 1.08689C0.0670791 0.967488 0 0.805545 0 0.636685C0 0.467826 0.0670791 0.305882 0.186481 0.18648C0.305882 0.0670788 0.467826 0 0.636685 0C0.805545 0 0.967488 0.0670788 1.08689 0.18648L7 6.10039L12.9131 0.18648C12.9722 0.127359 13.0424 0.0804609 13.1197 0.0484645C13.1969 0.0164681 13.2797 0 13.3633 0C13.4469 0 13.5297 0.0164681 13.607 0.0484645C13.6842 0.0804609 13.7544 0.127359 13.8135 0.18648C13.8726 0.245602 13.9195 0.31579 13.9515 0.393036C13.9835 0.470282 14 0.553075 14 0.636685C14 0.720296 13.9835 0.803088 13.9515 0.880334C13.9195 0.957581 13.8726 1.02777 13.8135 1.08689Z' fill='%23406B2D'/%3E%3C/svg%3E%0A");
    }

    @media (max-width: 640px) {
      max-width: calc(100vw - 98px) !important;

      .choices__list--single .choices__item {
        max-width: calc(100vw - 98px) !important;
      }
    }
  }
  .input-wr.input-wr-light-grey {
    margin-top: -4px;
  }
  .confirm-item-delivery-card {
    padding-bottom: 23px;
    .radio-wr {
      margin: 0;
    }
  }
  .cart-total {
    padding-top: 22px;
    &-wrapper {
      padding-top: ac(46px, 30px);
      ul {
        gap: 18.3px;
        padding-bottom: 0;
        li {
          font-size: ac(18px, 16px);
          line-height: 1.44;
          div {
            margin-right: auto;
          }
          &:last-of-type {
            font-size: ac(20px, 18px);
            margin-top: ac(-5px, 0px);
            padding-bottom: ac(21px, 16px);
          }
          &:nth-last-of-type(2) {
            border-bottom: 1px solid var(--light-gray);
            padding-bottom: 22px;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    &-title-wr {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: ac(52px, 25px);
      padding-right: 5px;
      flex-wrap: wrap;
      gap: 25px;
      h3 {
        font-size: ac(32px, 25px);
        font-weight: 700;
        font-family: var(--font-philosopher);
        line-height: 1.125;
      }
      .edit-btn {
        margin-bottom: ac(-4px, -2px);
      }
    }
    .btn {
      padding-right: 15px;
      i {
        font-size: 125%;
      }
    }
  }
  .checkbox-wr {
    max-width: 404px;
    font-size: 14px;
    line-height: 1.21;
    gap: 10px;
    padding-left: 2px;
    padding-bottom: 27px;
    .checkbox-icon {
      margin-top: 4px;
    }
  }
}

@media (max-width: 992px) {
  .confirm {
    flex-direction: column;
    &-steps {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .confirm-item-form-grid,
  .confirm-item-delivery-grid {
    grid-template-columns: 1fr;
  }
}
