:root {
  --plyr-color-main: var(--white);
  --plyr-video-control-color: var(--white);
  --plyr-video-control-color-hover: var(--primary-green);

  /*--plyr-control-spacing: ac(76px, 44px);*/
  @media (max-width: 375px) {
    --plyr-control-spacing: 6px;
  }
}

.plyr {
  cursor: pointer;
}

.plyr__control--overlaid {
  background: transparent !important;
  border: 1px solid var(--white) !important;
  transform: scaleX(1) translateX(-48%) translateY(-50%);
  padding: 5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: ac(120px, 50px) !important;
  height: ac(120px, 50px);
  opacity: 1 !important;
  --plyr-video-control-color: var(--white);
  --plyr-video-control-color-hover: var(--secondary-green);
  border-radius: 50%;

  &:hover {
    border-color: var(--secondary-green);
    background-color: transparent !important;
  }

  --plyr-control-icon-size: ac(64px, 20px);

  svg {
    left: 2px;
  }
}

.plyr-hide-controls {
  .plyr__controls {
    opacity: 0;
  }
}

.plyr__video-wrapper {
  z-index: 0;
}

.plyr__poster {
  background-size: cover;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding-bottom: 20px;
  }
}

@media (max-width: 390px) {
  .plyr__volume input[type='range'] {
    max-width: 60px;
  }
}
