.about-us-page {
  .goods-section-similar {
    padding-bottom: ac(82px, 42px);
  }
}

.hero-about {
  padding: ac(157px, 97px, 992) 0;

  .cont {
    display: flex;
    gap: ac(151px, 120px, 992);
  }

  &-thumbnail {
    flex-shrink: 0;
    position: relative;
    width: fit-content;
    align-self: flex-start;

    &-wr {
      position: relative;
      overflow: hidden;
      border: 12px solid var(--white);
      width: ac(516px, 325px, 992);
      max-width: 100%;
      border-radius: 50%;
      box-shadow: 0px 0px 40px 0px #00000040, 0px 4px 4px 0px #00000040;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      .swiper {
        position: absolute;
        left: 50% !important;
        overflow: hidden;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 12px);
        height: calc(100% + 12px);
        object-fit: cover;
        .swiper-wrapper {
          .swiper-slide {
            img {
              /* width: calc(100% + 12px);
              height: calc(100% + 12px);
              object-fit: cover;
              transition: 0.3s; */
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .swiper-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 110%;
      left: -5%;
      z-index: 5;
    }
    &-layer {
      position: absolute;
      z-index: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 152.65%;
      max-width: revert;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .title {
    font-size: ac(64px, 32px);
    line-height: 1.125;
    margin-bottom: 25px;
  }

  &-text {
    font-size: ac(18px, 16px);
    line-height: 1.55;
    position: relative;
    z-index: 2;
    width: 517px;
    padding-top: ac(59px, 0px);

    p {
      &:first-of-type {
        font-size: ac(22px, 19px);
        font-weight: 500;
        line-height: 1.2727;
        margin-bottom: 24px;
      }
    }
  }
}

.vision {
  &-section {
    background: var(--light-harvest);
    padding: ac(101px, 50px) 0 ac(120px, 50px);
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    &.animated {
      .vision-item {
        opacity: 1;
        transform: none;
      }
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: ac(18px, 16px);
    transform: translateY(30px);
    opacity: 0;
    transition-duration: 0.3s;

    &:nth-of-type(2) {
      transition-delay: 150ms;
    }
    &:nth-of-type(3) {
      transition-delay: 300ms;
    }

    &-icon {
      width: ac(88px, 60px);
      position: relative;
      overflow: hidden;
      margin-bottom: ac(20px, 10px);

      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.3s;
      }
    }

    &-title {
      font-size: ac(32px, 22px);
      font-weight: 700;
      font-family: var(--font-philosopher);
      color: var(--primary-green);
      margin-bottom: 16px;
      line-height: 1.125;
    }

    &-description {
      width: 100%;
      --line-count: 4;
      --fz: ac(18px, 16px);
      --line-height: 1.44;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    }
  }
}

.offer {
  &-section {
    padding: ac(120px, 50px) 0;

    .section-bg {
      width: perc(818);
      min-width: 150px;
      height: 100%;
      border-radius: 12px;

      mask-image: linear-gradient(
        90deg,
        #eef3fc 0%,
        rgba(238, 243, 252, 0) 100%
      );
      left: -100px;
      opacity: 0;
      transition: 0.8s 0.3s;
      @media (max-width: 992px) {
        img {
          opacity: 0.5;
        }
      }
    }
    &.animated {
      .section-bg {
        left: 0;
        opacity: 1;
      }
    }

    .cont {
      display: flex;
      justify-content: flex-end;
    }

    .title {
      margin-bottom: ac(56px, 36px);
    }
  }

  &-wrapper {
    max-width: 708px;
    width: 100%;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ac(32px, 16px) 20px;
  }

  &-item {
    display: flex;
    gap: 16px;
    align-items: center;

    &-icon {
      width: ac(80px, 50px);
      height: ac(80px, 50px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      flex-shrink: 0;
      background: var(--secondary-green);

      img {
        max-width: 60%;
        max-height: 60%;
      }
    }

    &-text {
      --line-count: 4;
      --fz: ac(20px, 16px);
      --line-height: 1.4;
      flex-grow: 1;
      max-width: calc(100% - ac(80px, 60px) - 16px);
      font-weight: 600;
      line-height: var(--line-height);
      font-size: var(--fz);
      max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    }
  }
}

.video-section {
  padding-top: ac(80px, 32px);
  padding-bottom: ac(80px, 32px);
  position: relative;
  overflow: visible;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 5;
  }

  &__title {
    text-align: center;
    margin-bottom: 12px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__text {
    text-align: center;
    max-width: ac(640px, 540px);
    margin: 0 auto;
    font-size: ac(18px, 16px);
    line-height: 1.555;
    padding-bottom: ac(46px, 35px);
  }

  &__video {
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    border: 2px solid var(--primary-green);
    max-width: ac(1039px, 900px);

    .plyr__poster {
      background-size: 101%;

      /*&:after {
        position: absolute;
        inset: 0;
        background: var(--black);
        opacity: 0.5;
        content: '';
        transition: 0.3s;
      }*/
    }

    &:hover {
      .plyr__poster {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;

    .swiper {
      width: 100%;
      max-width: 100%;
    }

    .swiper-buttons {
      width: 100%;
      justify-content: center;

      .swiper-button {
        margin-top: ac(32px, 24px);
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item-video {
    overflow: hidden;
    border-radius: ac(16px, 8px);
    border: 2px solid var(--primary-green);
    width: 100%;

    .plyr {
      --plyr-control-icon-size: 11px;
      --plyr-font-size-time: 11px;
    }

    .plyr__controls__item {
      &:first-child {
        margin-right: 4px;
      }
    }

    .plyr__controls__item.plyr__volume {
      max-width: 60px;
      margin-right: 30px;
    }

    .plyr__volume input[type='range'] {
      max-width: 60px;
    }

    .plyr__menu {
      display: none;
    }

    .plyr__control--overlaid {
      width: ac(76px, 50px) !important;
      height: ac(76px, 50px);
      --plyr-control-icon-size: ac(27px, 20px);

      @media (max-width: 1023px) {
        @media (min-width: 768px) {
          top: 45%;
        }
      }
    }

    .plyr__poster {
      background-size: 101%;
    }

    &:hover {
      .plyr__poster {
        &:after {
          opacity: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.team {
  &-section {
    background: var(--light-harvest);
    padding: ac(86px, 50px) 0 ac(84px, 50px);

    .title {
      text-align: center;
      margin-bottom: 12px;
    }

    .section-description {
      text-align: center;
      max-width: ac(640px, 540px);
      margin: 0 auto;
      font-size: ac(18px, 16px);
      line-height: 1.555;
      padding-bottom: ac(46px, 35px);
    }
  }

  &-swiper-wr {
    position: relative;
    padding: 0 ac(108px, 55px, 1100);

    .swiper-buttons {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      justify-content: space-between;
      pointer-events: none;

      .swiper-button {
        pointer-events: all;
      }
    }

    @media (max-width: 1300px) {
      max-width: 950px;
      margin: 0 auto;
    }
    @media (max-width: 992px) {
      max-width: 100%;
      padding: 0;
      .swiper {
        overflow: visible;

        &-slide {
          width: 250px;
          max-width: 100%;
        }
      }

      .swiper-buttons {
        position: static;
        transform: none;
        width: fit-content;
        margin: 0 auto;

        .swiper-button {
          margin-top: 35px;
        }
      }
    }
  }
}

.teammate-card {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid var(--secondary-green);
  overflow: hidden;
  background: var(--white);

  &-thumbnail {
    min-width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -2px;
    border: 2px solid var(--secondary-green);
    border-radius: 0 0 12px 12px;

    &::before {
      content: '';
      display: block;
      padding-top: 113.7%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-text {
    padding: 24px ac(19px, 15px, 1100) ac(26px, 20px);

    div {
      font-size: ac(22px, 20px);
      font-weight: 600;
      line-height: 1.0909;
      margin-bottom: 4px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-green);
    }
  }
}

@media (max-width: 992px) {
  .hero-about {
    padding-top: 120px;
    padding-bottom: 50px;

    .cont {
      flex-direction: column-reverse;
    }

    &-text {
      width: 100%;
      max-width: 100%;
    }

    &-thumbnail {
      align-self: center;
      max-width: 80%;
    }
  }
}

@media (max-width: 750px) {
  .vision-wrapper {
    grid-template-columns: minmax(0, 350px);
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .offer-list {
    grid-template-columns: 1fr;
  }
}
