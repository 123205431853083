.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  min-height: calc(100 * var(--vh));
  overflow: hidden;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1317px;
  width: perc(1317);
  padding: 0 20px;
  @media (max-width: 1100px) {
    width: 100%;
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(120px, 80px) 0 ac(80px, 40px);

  p {
    padding: 0;
    margin-bottom: 10px;
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ac(12px, 6px);
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0;
  flex-shrink: 0;
}

.swiper-button {
  position: relative;
  transform: none;
  margin: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  display: flex;
  width: ac(52px, 40px);
  height: ac(52px, 40px);
  justify-content: center;
  align-items: center;
  color: var(--white);
  border: 2px solid rgba(255, 255, 255.08);
  border-radius: 50%;
  transition: 0.3s;
  background: var(--primary-green);
  font-size: ac(19px, 12px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0);
  flex-shrink: 0;
  padding-left: 4px;

  &::after {
    display: none;
  }

  &::before {
    content: '\e906';
    display: block;
    font-family: 'icomoon' !important;
  }

  &.swiper-button-prev {
    padding-right: 4px;
    padding-left: 0;
    &::before {
      content: '\e905';
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  &:hover {
    background: var(--white);
    color: var(--primary-green);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border-color: var(--primary-green);
  }

  &.swiper-button-disabled {
    background: var(--white) !important;
    border: 2px solid #6b824c80 !important;
    color: var(--black);
    opacity: 1;
    cursor: not-allowed;
  }

  &.swiper-button-lock {
    display: none;
  }

  &.swiper-button-bordered {
    background: var(--white);
    border: 1px solid var(--secondary-green);
    color: var(--secondary-green);
    width: ac(48px, 40px);
    height: ac(48px, 40px);
    box-shadow: 0px 2px 4px 0px #00000014;

    &:hover {
      color: var(--white);
      background: var(--secondary-green) !important;
    }
    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.section-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

.bg-layer {
  position: absolute;

  img {
    width: 100%;
    height: auto;
  }
}

.swiper {
  width: 100%;
  left: 0 !important;
  margin: 0;
}

.swiper-pagination {
  position: relative;
  left: auto;
  top: auto;
  margin: 0;
  transform: none;
  display: flex;
  gap: 4px;
  bottom: 0 !important;

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--dark-gray);
    background: var(--light-harvest);
    transition: 0.3s;
    opacity: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid var(--light-gray);
      border-radius: inherit;
      background: var(--primary-green);
      opacity: 0;
      transition: 0.3s;
    }

    &.swiper-pagination-bullet-active {
      border: 2px solid var(--dark-gray);

      &::before {
        opacity: 1;
      }
    }
  }
}

.social-buttons {
  display: flex;
  width: fit-content;
  gap: 8px;
  align-items: center;
}
.social-btn {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  border: 1px solid var(--primary-green);
  border-radius: 50%;
  background: var(--white);
  transition: 0.3s;
  color: var(--primary-green);

  svg {
    width: 19px;
    height: 19px;

    path {
      fill: var(--primary-green);
    }
  }

  &:hover {
    background: var(--primary-green);
    color: var(--white);

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}
.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &-item {
    border-radius: 50%;
    width: ac(44px, 35px);
    height: ac(44px, 35px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border: 1px solid transparent;
    font-size: ac(18px, 14px);
    font-weight: 500;
    line-height: 1;
    transition: 0.3s;
    cursor: pointer;
    padding-top: ac(5px, 3px);

    &:hover {
      background: var(--secondary-green) !important;
      color: var(--white);
    }
    &.active {
      background: var(--light-harvest);
      border: 1px solid var(--secondary-green);
    }
  }
  span:not([class]) {
    color: var(--mid-gray);
    font-size: 18px;
    padding: 0 5.5px;
  }

  &-btn {
    background: transparent;
    border-color: transparent;
    line-height: 1;
    padding: 0;
    &.prev {
      padding-right: 3px;
    }
    &.next {
      padding-left: 3px;
    }
  }
}
.back-link {
  margin-left: 2px;
  display: inline-flex;
  width: fit-content;
  align-items: center;
  gap: 11px;
  font-size: ac(18px, 16px);
  font-weight: 600;
  line-height: 1.222;
  color: var(--primary-green);
  transition: 0.3s;
  margin-bottom: 35px;
  i {
    font-size: 90%;
    line-height: 1;
    display: inline-block;
    transition: 0.3s;
    transform: translate(0, -10%);
  }
  &:hover {
    color: var(--black);
    i {
      transform: translate(-3px, -10%);
    }
  }
}
.text-dark-gray {
  color: var(--dark-gray);
}
.text-green {
  color: var(--primary-green);
}
