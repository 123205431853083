.article-page {
  @media (min-width: 751px) {
    overflow: visible !important;
    .header .navbar:before {
      width: 99vw;
    }
  }
}

.hero-article {
  padding: ac(64px, 34px, 375) 0;

  .cont {
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: ac(56px, 32px);
    line-height: 1.125;
    margin-bottom: 31px;
  }

  &-thumbnail {
    flex-shrink: 0;
    width: ac(520px, 375px);
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--light-gray);
    border-radius: 20px;

    &::before {
      content: '';
      display: block;
      padding-top: 75%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-info {
    padding-top: 3px;

    ul {
      display: flex;
      gap: 13px;
      flex-wrap: wrap;
      padding-bottom: 18px;
      padding-left: 3px;

      li {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--dark-gray);

        i {
          color: var(--secondary-green);
          display: inline-block;
          transform: translateY(-15%);
        }

        b {
          color: var(--dark-gray);
          padding-left: 4px;
        }

        &.by {
          color: var(--primary-green);
        }
      }
    }

    .insights-form-categories-list {
      max-width: 650px;
    }
  }
}

.article-content {
  padding: ac(48px, 35px) 0 ac(87px, 45px);
  background: var(--light-harvest);
  transform: translate(0);
  position: relative;
  border-top: 1px solid var(--light-gray);

  .bg-layer-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    .bg-layer {
      position: absolute;
      right: -4px;
      bottom: -7px;
      top: auto;
      width: perc(446);
      height: auto;
      overflow: hidden;
      opacity: 0;
      transform: translate(10%, 10%) scale(0.8);

      &::before {
        content: '';
        display: block;
        padding-top: 138.42%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }

      &.animated {
        opacity: 0.2;
        transform: none;
      }
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    width: 100%;
  }

  &-main {
    flex-grow: 1;
    max-width: 65.9%;
  }

  &-aside {
    flex-shrink: 0;
    padding-top: ac(64px, 34px);
    position: relative;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: sticky;
      top: 140px;

      p {
        font-size: ac(22px, 18px);
        font-weight: 600;
        line-height: 1.2727;
        margin-bottom: 16px;
        color: var(--primary-green);
      }
    }
  }

  .content-elements {
    font-size: ac(18px, 16px);
    line-height: 1.5555;

    h2 {
      font-size: ac(40px, 26px);
      line-height: 1.125;
      margin-bottom: 17px;
      max-width: 790px;
    }

    h3 {
      font-size: ac(24px, 22px);
      line-height: 1.116;
      margin-bottom: 17px;
      font-family: var(--font-main);
      margin-top: 40px;
    }

    a {
      color: var(--primary-green);
      transition: 0.3s;
      font-weight: 500;

      &:hover {
        color: var(--black);
      }
    }

    p {
      margin-bottom: 40px;
    }

    ul,
    ol {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-left: ac(28px, 15px);
      margin: 25px 0;

      li {
        font-weight: 500;
      }
    }
  }

  &-social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 30px;

    p {
      padding-top: 2px;
      font-size: ac(20px, 18px);
      font-weight: 600;
      line-height: 1.2;
      color: var(--primary-green);
    }
  }
}

.article-swiper {
  &-header {
    border-radius: 20px;
    border: 1px solid #c1c7cd;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px ac(37px, 20px) 50px ac(40px, 20px);
    margin-bottom: -21px;
    flex-wrap: wrap;

    h2 {
      font-size: ac(40px, 25px);
      line-height: 1.125;
    }
  }

  &-wr {
    width: 100%;

    .swiper-buttons {
      gap: ac(14px, 12px);
    }

    .swiper {
      background: var(--white);
      width: 100%;
      border-radius: 20px;
      border: 1px solid #c1c7cd;
      overflow: hidden;

      &-slide {
      }
    }
  }
}

.article-gallery-item {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 56.48%;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
}

@media (max-width: 750px) {
  .article-content {
    &-main {
      max-width: 100%;
    }

    &-aside {
      display: none;
    }
  }

  .hero-article .cont {
    flex-direction: column-reverse;
    gap: 40px;
  }
}

@media (max-width: 550px) {
  .article-swiper-header {
    flex-direction: column;
    gap: 30px;
  }
}
.safari-15-support{
    .hero-article-info ul li i{
        transform: translateY(-21%);
    }
}