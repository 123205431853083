.hero-locations {
  padding: 61px 0 86px;

  .title {
    margin-bottom: ac(51px, 40px);
    font-size: ac(64px, 33px);
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
      margin-bottom: 20px;
  }
    &-tab{
        background: var(--white);
        border-radius: 12px;
        border: 1px solid var(--light-gray);
        padding: 24px ac(32px, 20px) 30px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: 0.3s;
        &-title{
            font-family: var(--font-philosopher);
            font-size: ac(32px, 25px);
            font-weight: 700;
            line-height: 1.125;
            color: var(--primary-green);
            margin-bottom: 20px;
            transition: 0.3s;
            padding-left: 4px;

          i{
              display: inline-flex;
              margin-right: 6px;
              font-size: ac(28px, 20px);
              color: var(--secondary-green);
              transition: 0.3s;
          }
        }
        &-description{
            font-size: ac(18px, 16px);
            font-weight: 400;
            line-height: 1.44;
            margin-bottom: ac(32px, 20px);
        }
        &-button{
            margin-top: auto;
            color: var(--primary-green);
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: ac(18px, 16px);
            font-weight: 600;
            transition: 0.3s;
            width: fit-content;

            i{
                font-size: 14px;
                display: inline-block;
                transition: 0.3s;
                transform: translateY(-5%);

            }
            &:hover{
                i{
                    transform: translate(5px, -5%);
                }
            }
        }

        &:hover, &.active{
            background: var(--primary-green);
            color: var(--white);
            .hero-locations-tab-title, .hero-locations-tab-button{
                color: var(--white);
                i{
                    color: var(--white);
                }
            }
        }
    }
    &-map{
        position: relative;
        overflow: hidden;
        min-height: 300px;
        border: 1px solid #C1C7CD;
        border-radius: 12px;
        &::before{
            content: '';
            display:block;
            padding-top: 44.88%;
        }
        iframe{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    }
}

@media (max-width: 750px) {
    .hero-locations-list{
        grid-template-columns: 1fr;
    }
}
.safari-15-support{
    .hero-locations-tab-button{
        i{
            transform: translateY(-20%);
        }
        &:hover{
            transform: translate(5px, -20%);
        }
    }
}