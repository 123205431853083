/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  /*-moz-appearance: textfield;*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 0;
  pointer-events: none;
  display: none;
}

input,
textarea {
  @mixin transparent-bg-input var(--black);

  &::placeholder {
    color: inherit;
    opacity: 0.7;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}

textarea {
  height: 96px;
  resize: none;
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(18px, 16px);
    min-width: ac(18px, 16px);
    height: ac(18px, 16px);
    border: 1px solid var(--primary-green);
    margin-right: ac(8px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--secondary-green);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--secondary-green);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    a {
      font: inherit;
      font-weight: 700;
      color: var(--primary-green);
      text-decoration: underline;
      transition: all 0.25s ease;

      &:hover {
        color: var(--secondary-green);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--secondary-green);
    }
  }
}

.input-wr {
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: var(--light-harvest);
    line-height: 1.714;
    b {
      font-weight: inherit;
      color: #fde28a;
    }
  }
  input,
  textarea {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-main);
    background: transparent;
    border: 1px solid var(--white);
    box-shadow: 0 0 0 2px var(--primary-green);
    border-radius: 6px;
    padding: 12px 14px 9px 16px;
    width: 100%;
  }
  &.input-wr-light {
    color: var(--black);
    span {
      color: inherit;
    }
    b {
      color: var(--primary-green);
    }
    input {
      border: 1px solid var(--light-gray);
      box-shadow: none;
      background: var(--white);
    }
  }
  &.input-wr-light-grey {
    color: var(--black);
    span {
      color: inherit;
    }
    b {
      color: var(--primary-green);
    }
    input {
      border: 1px solid var(--light-gray);
      box-shadow: none;
      background: var(--light-bg);
    }
  }
}
label.checkbox-wr,
.checkbox-wr {
  --checkbox-icon-size: 20px;
  --checkbox-mark-color: var(--white, #e1e8ff);
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;

  input {
    display: none;
    &:checked + .checkbox-icon {
      &::before {
        opacity: 1;
      }
    }
  }

  .checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--checkbox-icon-size);
    height: var(--checkbox-icon-size);
    border: 2px solid var(--dark-gray);
    border-radius: 2px;
    flex-shrink: 0;
    transition: 0.3s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -2px;
      top: -2px;
      border-radius: 2px;
      width: var(--checkbox-icon-size);
      height: var(--checkbox-icon-size);
      background: var(--checkbox-mark-color);
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 18V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18ZM4.01226 11.4754C4.08305 11.6463 4.18682 11.8016 4.31763 11.9324L7.13013 14.7438C7.26077 14.8749 7.41601 14.9789 7.58694 15.0499C7.75788 15.1208 7.94114 15.1574 8.12622 15.1574C8.3113 15.1574 8.49456 15.1208 8.66549 15.0499C8.83643 14.9789 8.99167 14.8749 9.12231 14.7438L15.6848 8.18127C15.949 7.91709 16.0974 7.55879 16.0974 7.18518C16.0974 6.81157 15.949 6.45327 15.6848 6.18909C15.4206 5.92491 15.0623 5.77649 14.6887 5.77649C14.3151 5.77649 13.9568 5.92491 13.6926 6.18909L8.12505 11.7578L6.30981 9.94026C6.179 9.80945 6.02371 9.70569 5.8528 9.63489C5.68189 9.5641 5.49871 9.52766 5.31372 9.52766C5.12873 9.52766 4.94555 9.5641 4.77464 9.63489C4.60373 9.70569 4.44843 9.80945 4.31763 9.94026C4.18682 10.0711 4.08305 10.2264 4.01226 10.3973C3.94147 10.5682 3.90503 10.7514 3.90503 10.9364C3.90503 11.1213 3.94147 11.3045 4.01226 11.4754Z' fill='%23406B2D'/%3E%3C/svg%3E%0A");
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  a {
    display: inline;
    color: var(--primary-green);
    text-decoration: underline;
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    .checkbox-icon {
      border-color: var(--primary-green);
    }
  }
}

label.radio-wr,
.radio-wr {
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: ac(20px, 18px);
  font-weight: 600;
  align-items: center;
  width: fit-content;
  max-width: 100%;

  b {
    font-weight: inherit;
  }

  input {
    display: none;
    &:checked + .radio-icon {
      border-color: var(--primary-green);
      &::before {
        opacity: 1;
      }
    }
  }

  .radio-icon {
    width: ac(28px, 20px);
    height: ac(28px, 20px);
    border: 2px solid var(--dark-gray);
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    padding: 3px;
    transform: translateY(-9%);
    flex-shrink: 0;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: var(--primary-green);
      opacity: 0;
      transition: opacity 0.3s;
      border-radius: inherit;
    }
  }
}
