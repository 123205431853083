.hero-insights {
  padding: ac(100px, 71px) 0 48px;
}
.insights-form {
  display: flex;
  justify-content: space-between;
  gap: 35px;
  align-items: flex-end;
  .title {
    font-size: ac(32px, 22px);
    margin-bottom: 24px;
    margin-top: 15px;
  }

  &-search {
    flex-shrink: 0;
    width: ac(412px, 375px);
    max-width: 100%;
  }
  &-categories {
    flex: 1;
    max-width: 628px;
    width: 100%;
    h3 {
      width: 100%;
      cursor: pointer;
      transition: 0.3s;
      font-size: ac(22px, 18px);
      font-weight: 600;
      font-family: var(--font-main);
      line-height: 1.2727;
      display: flex;
      justify-content: space-between;
      gap: 15px;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--light-gray);
      margin-bottom: 24px;
      padding-right: 11px;

      &:hover {
        color: var(--primary-green);
      }
      i {
        font-size: 80%;
        display: inline-block;
      }
      &:not(.active) {
        i {
          transform: rotateX(180deg);
        }
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    &-item {
      span {
        transition: 0.3s;
        border: 1px solid var(--light-gray);
        background: var(--light-harvest);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 15.1px 2px;
        min-height: 32px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        color: var(--primary-green);
        box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0);
        will-change: transform, box-shadow;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
      input {
        display: none;
        &:checked + span {
          background: var(--secondary-green);
          border-color: var(--secondary-green);
          color: var(--white);
        }
      }
    }
  }
  .product-search-input-wr input {
    padding-top: 10px;
    padding-bottom: 8px;
    font-weight: 500;
  }
}

.insights-results {
  padding-bottom: ac(86px, 40px);
  .title {
    margin-bottom: 5px;
  }
  &-info {
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 38px;
  }
  &-count {
    padding-bottom: 2px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    font-size: ac(18px, 16px);
    line-height: 1.222;
    font-weight: 500;
    align-items: center;

    b {
      font-weight: 600;
      color: var(--primary-green);
      font-size: 20px;
      /*line-height: 1.2;*/
    }
  }
  .meta-select {
    flex-shrink: 0;

    .choices {
      width: 196px;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 40px 20px;
    .blog-card {
      max-width: 100%;
      width: 100%;
    }
  }
  .pagination-wrapper {
    padding-top: 64px;
  }
}

@media (max-width: 750px) {
  .insights-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .insights-results-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 550px) {
  .insights-results-grid {
    grid-template-columns: 1fr;
  }
}
