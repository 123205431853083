html {
  font-size: var(--fz);
  font-family: var(--font-main);
  background: var(--white);
  color: var(--black);
  line-height: 1.5;

  scrollbar-width: thin;
  scrollbar-color: var(--primary-green) rgba(255, 255, 255, 0.2);

  [data-simplebar] {
    width: 100%;
  }

  .simplebar-scrollbar::before {
    background-color: var(--primary-green);
    color: var(--primary-green);
    opacity: 1 !important;
    left: 1px;
    right: 1px;
  }

  .simplebar-track.simplebar-vertical {
    background: rgba(255, 255, 255, 0.2) !important;
    opacity: 1 !important;
    width: 5px;
    border-radius: 5px;
    transform: translateX(8px);
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.15;
  font-weight: 700;
  font-family: var(--font-philosopher);
}

h1 {
  font-size: ac(88px, 35px);
  line-height: 1.25;
  margin-bottom: 16px;
}

h2 {
  font-size: ac(44px, 28px);
  line-height: 1.1136;
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
}

li {
}

a {
}

.heading-4 {
  font-size: ac(35px, 20px);
  grid-column: 1 / -1;
}

.content-elements {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.15;
    font-weight: 700;
    font-family: var(--font-philosopher);
    margin-bottom: 15px;
  }

  h1 {
    font-size: ac(88px, 40px);
    color: var(--black);
  }

  h2 {
    font-size: ac(44px, 28px);
  }

  h3 {
    font-size: ac(40px, 22px);
    font-weight: 600;
  }

  h4 {
    font-size: ac(35px, 20px);
  }

  h5 {
    font-size: ac(33px, 19px);
  }

  h6 {
    font-size: ac(30px, 20px);
  }

  ul,
  ol {
    list-style: disc;
    padding-left: 23px;
    margin: 20px 0;
  }

  ol {
    list-style: decimal;
  }

  a:not([class]) {
    display: inline;
    color: var(--purple);
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: 0.3s ease;

    &:hover {
      color: var(--orange);
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
