.hero-contact {
  padding: 60px 0 86px;

  .title {
    font-size: ac(64px, 32px);
    margin-bottom: 11px;
  }
    .sub-title{
        margin-bottom: 32px;
    }

  .contact-info-list {
    max-width: 100%;
    width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
      margin-bottom: 48px;
      li{
          border-color: var(--light-gray);
          background: var(--light-harvest);
          color: var(--black);
      }
  }
    .hero-locations-map{
        min-height: 300px;
        &:before{
            padding-top: 39.08%;
        }
    }
}
.contact-section.contact-section-thumbnail{
    overflow: hidden;
    padding: ac(74px, 50px) 0;
    .contact-wrapper{
        gap: ac(57px, 35px);
        align-items: center;
    }
    .title{
        margin-bottom: ac(42px, 25px);
    }

    .contact-form-wrapper{
     max-width: 660px;
        padding-top:ac(40px, 20px);

    }
}

.contact-thumbnail{
    position: relative;
    margin-left: ac(-7px, 0px);
    flex-shrink: 0;
    max-width: 80%;
    &-wrapper{
        width: ac(568px, 375, 1100);
        max-width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        border: 8px solid var(--primary-green);
        z-index: 4;
        &::before{
            content: '';
            display:block;
            padding-top: 100%;
        }
        img{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 0px);
            height: calc(100% + 0px);
            max-width: revert;
            object-fit: cover;
            transition: 0.3s;
        }
    }
    .bg-layer{
        width: 1430px;
        left: 50%;
        top: 50%;
        transform: translate(-47.8%, -48.2%);
        opacity: 0.5;
        max-width: 252%;

        img{
            width: 100%;
            height: auto;
        }
        &.mix-blend-hard-light{
            img{
                opacity: 0.3;
            }
        }
    }
}

.history{
    &-section{
        padding: ac(120px, 50px) 0 ac(118px, 50px);
        background: var(--light-harvest);
        transform: translate(0);
        .section-bg{
            width: perc(818);
            height: 100%;
            left: auto;
            right: 0;
            mask-image: linear-gradient(90deg, rgba(238, 243, 252, 0) 0%, #EEF3FC 100%);
            img{
                @media (max-width: 992px){
                    opacity: 0.5;
                }
            }
        }

        .title{
            margin-bottom: 24px;
        }

        .btn-wr{
            margin-top: ac(30px, 25px);
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
        }

    }
    &-content{
        width: ac(622px, 375px);
        max-width: 100%;
        line-height: 1.5555;
        font-size: ac(18px, 16px);
        p{
            margin-bottom: 16px;
            &:first-of-type{
                font-size: ac(22px, 18px);
                line-height: 1.2727;
                font-weight: 500;

            }
        }
    }


}

@media (max-width: 992px) {
    .hero-contact .contact-info-list{
        grid-template-columns: 1fr;
    }
}