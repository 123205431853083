.hero-swiper-wr {
  overflow: hidden;
  position: relative;

  .section-bg {
    background: var(--white);

    img {
      opacity: 0.1;
    }
  }

  .bg-layer {
    right: 0;
    transform: translate(69.7%, -48.7%);
    top: 50%;
    opacity: 0.6;
    width: perc(1338);
    img {
      width: 100%;
      height: auto;
    }
  }

  .cont {
    position: relative;
  }

  .swiper-buttons {
    position: absolute;
    right: ac(19px, 10px);
    bottom: ac(40px, 20px);
    gap: ac(14px, 10px);
    z-index: 2;
  }
}

.hero-banner {
  padding: 60px 0 104px;
  min-height: ac(572px, 502px, 1280, 1440);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .section-description {
    font-size: ac(24px, 16px);
    line-height: 1.33;
    margin-bottom: ac(37px, 26px);
    width: ac(736px, 228px, 320);
    max-width: 100%;
  }

  h1 {
    margin-bottom: 13px;
  }
}

.goods-section {
  padding: ac(85px, 55px) 0 ac(82px, 35px);

  .title-wr {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    padding-bottom: ac(60px, 40px);
    flex-wrap: wrap;
    align-items: center;

    .btn {
      margin-left: auto;
    }
  }

  .title {
    position: relative;
    padding-left: ac(64px, 32px);

    img {
      position: absolute;
      left: ac(7px, -5px, 375);
      top: 50%;
      transform: translate(0, -50%);
      width: ac(40px, 28px);
      height: auto;
    }
  }
}

.goods-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: ac(31px, 25px) 20px;
}

.goods-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0);
  transition: 0.3s;
  min-height: 100%;

  &-thumbnail-wr {
    position: relative;
  }

  &-discount {
    position: absolute;
    top: 13px;
    left: 14px;
    background: var(--white);
    border: 1px solid var(--secondary-green);
    display: flex;
    color: var(--primary-green);
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    min-height: 32px;
    box-shadow: 0px 2px 4px 0px #0000001f;
    border-radius: 4px;
    line-height: 1;

    div {
      padding: ac(5px, 2px) 10px 1px 9px;
    }

    span {
      color: var(--white);
      background: var(--primary-green);
      align-self: stretch;
      display: flex;
      align-items: center;
      padding: ac(5px, 2px) 12px 1px 8px;
    }
  }

  &-preview {
    border: 1px solid var(--secondary-green);
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    font-size: 22px;
    cursor: pointer;
    transition: 0.3s;
    color: var(--secondary-green);
    opacity: 0;

    &:hover {
      color: var(--black);
      border: 1px solid var(--white);
    }
  }

  &-save {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    bottom: 8px;
    background: var(--light-harvest);
    font-size: 21px;
    color: var(--primary-green);
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s;

    i {
      font-size: 21px;
      transition: opacity 0.3s;

      &:nth-of-type(2) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        opacity: 0;
      }
    }

    .icon-save-full {
      font-size: 86%;
    }

    &.active {
      i {
        opacity: 0;

        &:nth-of-type(2) {
          opacity: 1;
        }
      }
    }

    &:hover {
      color: var(--black);
    }
  }

  &-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid var(--light-gray);

    &::before {
      content: '';
      display: block;
      padding-top: 64.07%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-inner {
    padding: 10px 19px 19px;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-reviews {
    font-weight: 600;
    font-size: 14px;
    color: var(--dark-gray);
    margin-bottom: ac(12px, 8px);
    text-transform: capitalize;
  }

  &-description {
    @mixin max-line-length 3;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 12px;
  }

  &-stock {
    color: var(--primary-green);
    font-size: 14px;
    line-height: 1.2142;
    font-weight: 600;
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--light-gray);
    transition: background 0.3s;
    margin-top: auto;
  }

  &-price {
    font-size: ac(22px, 18px, 1200);
    color: var(--black);
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    padding: 18px 5px 11px 20px;
    align-items: flex-end;
    flex: 1;
    flex-wrap: wrap;
    min-height: ac(54px, 47px);

    b {
      font-size: 81.81%;
      color: var(--secondary-green);
      padding-left: 2px;
    }

    span {
      font-size: ac(14px, 12px);
      padding-left: 7px;
      color: var(--dark-gray);
      padding-bottom: ac(4px, 0px);
      display: block;
      transition: font-size 0.2s 0.3s;
    }
  }

  &-order {
    flex-shrink: 0;
    width: 64px;
    background: rgba(255, 249, 229, 0.5);
    position: relative;
    transform: translate(0);
    cursor: pointer;
    overflow: hidden;
    border-left: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);
    margin-bottom: -1px;
    transition: 0.1s;
    border-radius: 0 0 12px 0;
    min-height: 100%;

    &::before {
      content: '';
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      background: var(--primary-green);
      transition: 0.3s;
    }

    &-basket {
      display: flex;
      width: 44px;
      height: 44px;
      color: var(--white);
      justify-content: center;
      align-items: center;
      font-size: 20px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      transition: opacity 0.3s 0.2s;
    }

    &-btn {
      position: relative;
      width: 28px;
      height: 28px;
      cursor: pointer;
      transition: 0.3s;
      flex-shrink: 0;

      &:hover {
        opacity: 0.8;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 15px;
        height: 2px;
        border-radius: 2px;
        background: var(--white);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        height: 15px;
        width: 2px;
      }

      &.minus {
        &::after {
          display: none;
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &-qty {
      flex: 1;
    }

    &-controls {
      display: flex;
      align-items: center;
      position: absolute;
      justify-content: space-between;
      left: 50%;
      top: 0;
      width: 100%;
      max-width: 100%;
      transform: translateX(-50%);
      height: 100%;
      color: var(--white);
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-poppins);
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
      padding: 0 10px;

      input {
        width: 36px;
        max-width: 36px;
        background: transparent;
        color: var(--white);
        height: 20px;
        max-height: 20px;
        font-size: 16px;
        font-weight: 500;
        font-family: var(--font-poppins);
        text-align: center;
      }
    }

    &:hover {
      &::before {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }

  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

    .goods-card-footer {
      background: rgba(255, 249, 229, 0.5);
    }

    .goods-card-preview {
      opacity: 1;
    }

    .goods-card-description {
      text-decoration: underline;
      color: var(--primary-green);

      a {
        text-decoration: underline;
      }
    }
  }

  &.active {
    .goods-card-order {
      border-radius: 0 0 12px 12px;
      width: 112px;
      &::before {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
      &-basket {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s;
      }
      &-controls {
        opacity: 1;
        pointer-events: all;
        transition: 0.3s 0.3s;
      }
    }
    .goods-card-price span {
      font-size: 0;
      transition: font-size 0.2s;
    }
  }
}

.blogs-section {
  padding: ac(82px, 50px) 0 ac(83px, 50px);
  transform: translate(0);
  overflow: hidden;

  .title-wr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px ac(25px, 11px);
    padding-bottom: ac(55px, 40px);

    .swiper-buttons {
      margin-left: auto;
    }
  }

  .read-more-wr {
    display: flex;
    width: 100%;
    justify-content: center;

    .btn {
      margin-top: 56px;
    }
  }

  .title {
    position: relative;
    padding-left: ac(53px, 23px);
    font-size: ac(56px, 28px);

    img {
      position: absolute;
      left: ac(0, -5px, 375);
      top: 50%;
      transform: translate(0, -50%);
      width: ac(37px, 20px);
      height: auto;
      opacity: 0.7;
    }
  }

  .bg-layers {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    div {
      position: absolute;
      bottom: 0;
      width: perc(433);
      max-width: 433px;
      min-width: 160px;
      transition: 0.8s;
      opacity: 0;

      img {
        width: 100%;
        height: auto;
      }

      &:nth-of-type(1) {
        left: 0;
        transform: translateX(-50%);
      }

      &:nth-of-type(2) {
        width: perc(494);
        max-width: 494px;
        right: 0;
        transform: translateX(50%);
      }

      &.animated {
        opacity: 1;

        &:nth-of-type(1) {
          transform: none;
        }

        &:nth-of-type(2) {
          transform: none;
        }
      }
    }
  }
}

.blogs-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      width: ac(412px, 375px);
      max-width: 100%;
      display: flex;
      height: auto;
    }
  }
}

.blog-card {
  width: 412px;
  max-width: 100%;
  border-radius: 12px;
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0px 4px 16px 0px #00000000;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-thumbnail {
    position: relative;
    overflow: hidden;
    border-radius: 0 0 12px 12px;
    border: 1px solid var(--light-gray);
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px;

    &::before {
      content: '';
      display: block;
      padding-top: 75%;
      position: relative;
      z-index: 2;
      background: var(--black);
      opacity: 0;
      transition: 0.2s;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.8s;
      max-width: revert;
      border-radius: 12px;
    }
  }

  &-date {
    background: var(--white);
    padding: 11px 8px 7px;
    min-height: fit-content;
    min-width: 56px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;

    align-items: center;
    border: 1px solid #6b824c;
    position: absolute;
    left: 27px;
    bottom: 25px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px #0000001f;
    text-align: center;
    z-index: 3;

    div {
      font-size: ac(20px, 18px);
      font-weight: 600;
      line-height: 1;
      color: var(--primary-green);
      margin-bottom: 4px;
    }

    span {
      text-transform: uppercase;
      font-size: 14px;
      color: var(--dark-gray);
      line-height: 1;
      letter-spacing: 0.1px;
      font-weight: 600;
    }
  }

  &-text-content {
    padding: 22px ac(28px, 20px);
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-category {
    display: flex;
    align-items: center;
    gap: 15px ac(15px, 8px);
    flex-wrap: wrap;
    margin-bottom: 17px;

    li {
      display: flex;
      gap: 4.5px;
      align-items: center;
      font-size: 14px;

      i {
        color: var(--secondary-green);
        line-height: 1;

        &.icon-user {
          padding-bottom: 3px;
        }
      }

      span {
        font-weight: 600;
        color: var(--dark-gray);

        b {
          font-weight: inherit;
          color: var(--dark-gray);
        }
      }

      .by {
        color: var(--primary-green);
      }

      &:nth-of-type(2) {
        gap: 8px;
      }
    }
  }

  &-description {
    font-size: ac(24px, 18px);
    font-weight: 600;
    line-height: 1.166;
    width: 100%;
    margin-bottom: ac(63px, 40px);
    @mixin max-line-length 3;
  }

  .btn-wr {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
  }

  &-btn {
    font-size: ac(18px, 16px);
    font-weight: 600;
    line-height: 1.21;
    display: flex;
    align-items: center;
    gap: 11px;
    flex-shrink: 0;
    color: var(--primary-green);
    margin-bottom: ac(7px, 0px);
    transition: 0.3s;

    i {
      font-size: 16px;
      padding-bottom: ac(5px, 3px);
      display: inline-block;
      transition: 0.3s;
    }
    &:hover {
      color: var(--black);
      i {
        transform: translateX(5px);
      }
    }
  }

  &:hover {
    background: var(--light-bg);
    box-shadow: 0px 4px 16px 0px #00000014;
    .blog-card-thumbnail {
      &::before {
        opacity: 0.3;
      }
    }

    img {
      width: 105%;
    }
  }
}

.contact-section {
  padding: ac(122px, 60px) 0 ac(80px, 45px);
  background: #4b7f31;
  transform: translate(0);
  color: var(--white);
  overflow: hidden;
  .section-bg {
    opacity: 1;
    mask-image: radial-gradient(
      89.37% 89.37% at 66.91% 53.65%,
      rgba(217, 217, 217, 0) 0%,
      rgba(217, 217, 217, 0.1) 100%
    );
    mask-repeat: no-repeat;
    mask-size: cover;
    img {
      opacity: 0.7;
    }
  }

  .section-description {
    color: #fef1c7;
    font-size: ac(20px, 16px);
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: ac(41px, 26px);
  }

  .title {
    font-size: ac(64px, 28px);
    line-height: 1.125;
    margin-bottom: 15px;
  }
}

.contact {
  &-wrapper {
    display: flex;
    gap: ac(82px, 35px);
    justify-content: center;
  }

  &-info-list {
    flex: 1;
    max-width: ac(412px, 312px);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 8px;
    padding-top: 17px;
    align-self: flex-start;
    filter: drop-shadow(0 0 56px #00260314);

    li {
      background: var(--secondary-green);
      padding: 20px ac(50px, 20px);
      border: 2px solid var(--primary-green);
      border-radius: 12px;
      display: flex;
      gap: ac(28px, 20px);
      align-items: center;
      color: var(--light-harvest);
      font-size: ac(20px, 16px);
      font-weight: 500;
      line-height: 1.3;
      min-height: 160px;

      .img-wr {
        width: ac(51px, 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 51px;
          object-fit: contain;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
      }

      a {
        transition: 0.3s;

        &:hover {
          color: var(--yellow);
        }
      }
    }
  }

  &-form-wrapper {
    flex: 1;
    max-width: 566px;
  }

  &-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    input,
    textarea {
      @mixin transparent-bg-input var(--white);
      color: var(--white);
      background: #4b7f31 !important;
    }

    .btn {
      margin-top: 16px;
      padding: 8px 16px 7px 24px;
      gap: 12px !important;
    }
  }
}

@media (max-width: 1200px) {
  .goods-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 25px 15px;
  }
}

@media (max-width: 992px) {
  .goods-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .contact {
    &-info-list {
      align-self: center;
    }

    &-wrapper {
      flex-direction: column-reverse;
      align-items: center;
      .btn.btn-border-green {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-form-wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .goods-grid {
    grid-template-columns: 1fr;
  }

  .contact-form {
    grid-template-columns: 1fr;
  }

  .hero-banner {
    min-height: calc(100 * var(--vh, 1vh) - var(--header-height));
  }
  .goods-section {
    padding-bottom: 130px;
    .title-wr {
      position: static;
      transform: none !important;
      will-change: revert;
      .btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        max-width: 100%;
        width: max-content;
      }
    }
  }
}

@media (max-width: 450px) {
  .hero-swiper-wr {
    .bg-layer {
      opacity: 0.3;
      width: 790px;
      transform: translate(80.7%, -48.7%);
    }
    .swiper-buttons {
      left: 50%;
      transform: translate(-50%) !important;
    }
  }
}

@media (max-width: 370px) {
  .blogs-section .title {
    font-size: 23px;
  }
}
