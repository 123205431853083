.hero-cart {
  padding: 56px 0 82px;

  .cont {
    position: relative;
  }

  .bg-layer {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    z-index: -1;
    width: 462px;
    max-width: 38%;
    transform: translate(3.9%, 48%) scale(0.8);
    opacity: 0;
    transition: 0.3s 0.3s;

    img {
      width: 100%;
      height: auto;
      opacity: 0.2;
    }

    &.animated {
      transform: translate(3.9%, 48%);
      opacity: 1;
    }
  }

  .back-link {
    margin-bottom: ac(39px, 30px);
  }

  .title {
    font-size: ac(44px, 32px);
    margin-bottom: ac(45px, 35px);
  }
}

.cart {
  display: flex;
  gap: ac(48px, 25px);

  h2 {
    font-size: ac(32px, 25px);
    width: 100%;
    background: var(--light-harvest);
    border-bottom: 1px solid var(--light-gray);
    padding: 24px ac(40px, 20px) 18px;
  }

  &-products {
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid var(--light-gray);
    flex-shrink: 0;
    width: ac(736px, 468px);

    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 ac(24px, 15px, 1100) 3px;
    }

    &-item {
      padding: 24px 0 28px;
      display: flex;
      align-items: center;
      gap: ac(24px, 10px);
      position: relative;

      &::before {
        content: '';
        display: block;
        width: calc(100% - 5px);
        position: absolute;
        left: 5px;
        bottom: 0;
        background-image: url('../../assets/images/cart-products-item-border-bottom.png');
        background-repeat: repeat-x;
        height: 4px;
        -webkit-background-size: auto 100%;
        background-size: auto 100%;
      }

      &:last-of-type {
        &::before {
          display: none;
        }
      }

      &-inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-main {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;

        @media (min-width: 451px) {
          .product-order-controls {
            margin-left: auto;
          }
        }
      }

      &-description {
        @mixin max-line-length 2;
        font-size: ac(18px, 16px);
        font-weight: 600;
        line-height: 1.333;
        transition: 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }

      &-thumbnail {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        width: ac(120px, 80px);
        flex-shrink: 0;
        box-shadow: 0 0 0 0 var(--secondary-green);
        transition: 0.3s;

        &::before {
          content: '';
          display: block;
          padding-top: 80%;
        }

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }

        &-icon {
          font-size: ac(23px, 18px);
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--black);
          border: 1px solid var(--white);
          background: rgba(255, 255, 255, 0.8);
          position: absolute;
          z-index: 2;
          left: 50%;
          top: 50%;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;
          opacity: 0;
        }
      }

      .remove-btn {
        margin-left: ac(41px, 15px, 1100);
        margin-top: 2px;
        margin-right: ac(9px, 0px);
      }

      &-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
      }

      &-info {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        font-size: ac(16px, 14px);
        font-weight: 500;
        font-family: var(--font-inter);

        span {
          color: var(--primary-green);
        }
      }

      &-price {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: ac(22px, 18px);
        font-weight: 600;
        line-height: 1;
        transform: translate(2px, 1px);

        b {
          color: var(--secondary-green);
          font-size: 81.81%;
          line-height: 1;
          display: inline-block;
          transform: translateY(5%);
        }
      }

      &:hover {
        .cart-products-item {
          &-thumbnail {
            box-shadow: 0 0 0 1px var(--secondary-green);

            &-icon {
              opacity: 1;
            }
          }

          &-description {
            color: var(--primary-green);
          }
        }

        .product-order-btn {
          background: var(--secondary-green);

          &::before,
          &::after {
            background: var(--white);
          }
        }
      }
    }
  }

  &-total-wrapper {
    flex: 1;
    padding-top: ac(67px, 30px);

    ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      padding-bottom: 6px;
      border-bottom: 1px solid var(--light-gray);

      li {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        font-weight: 500;
        font-size: ac(20px, 18px);

        &::before {
          content: '';
          display: block;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: url('../../assets/images/cart-products-item-border-bottom.png');
          background-repeat: repeat-x;
          height: 4px;
          -webkit-background-size: auto 100%;
          background-size: auto 100%;
        }

        b {
          color: var(--secondary-green);
          font-size: 88.8888%;
          display: inline-block;
          transform: translateY(-5%);
          padding-left: 4px;
          font-weight: inherit;
        }

        em {
          font-style: normal;
          font-size: ac(18px, 16px);
          color: var(--dark-gray);
          padding-right: 5px;
          padding-left: ac(7px, 3px);
          display: inline-block;
          min-width: ac(44px, 0px);
          flex-shrink: 0;
          font-weight: 600;
        }

        div {
          @mixin max-line-length 1;
        }

        span {
          font-size: ac(18px, 16px);
          font-weight: 600;
          flex-shrink: 0;
          padding-left: ac(65px, 15px);
        }

        &:last-of-type {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &-total {
    padding-top: 24px;

    p {
      color: var(--dark-gray);
      font-size: ac(20px, 18px);
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 5px;
    }

    &-controls {
      display: flex;
      width: 100%;
      gap: 15px 0;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;

      span {
        font-size: ac(36px, 24px);
        font-weight: 600;

        b {
          color: var(--secondary-green);
          font-size: 80%;
          padding-left: 4px;
        }
      }

      .btn {
        margin-bottom: ac(16px, 10px);
      }
    }
  }
}

@media (max-width: 992px) {
  .cart {
    flex-direction: column;

    &-products {
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .cart-products-item-main {
    flex-wrap: wrap;
  }
}

@media (max-width: 450px) {
  .cart-products-item {
    flex-direction: column;

    &-thumbnail {
      width: 120px;
    }

    &-main {
      flex-direction: column;
      gap: 25px;
      padding-bottom: 25px;
    }

    &-description {
      text-align: center;
    }

    .remove-btn {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .cart-total-controls {
    flex-direction: column;
    align-items: flex-start;

    .btn {
      margin: 0 auto;
    }
  }
}
