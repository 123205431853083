.meta-select {
  --max-width: var(--width-select, 100%);
  --font-main: var(--font-main, 'sans-serif');
  --fz: 14px;

  --line-height: 1.2142;

  --border-color: var(--light-gray);

  --list-border-color: var(--light-gray);

  --text-color: var(--black);
  --hover-item-color: var(--secondary-green);
  --hover-item-bg: transparent;
  --font-weight: 500;

  --select-border-radius: 6px;
  --select-padding: 14px 42px 10px 16px;
  --selct-bg-color: var(--white, #fff);

  --dropdown-bg-color: var(--white, #fff);
  --dropdown-border: 1px solid var(--border-color);
  --dropdown-item-padding: 17px 5px 17px 16px;
  --chevron-color: var(--mid-gray);

  --list-item-selected-color: var(--primary-green);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &.meta-select-language {
    --border-color: transparent;
    --select-border-radius: 0;
    --select-padding: 6.5px;
    --dropdown-item-padding: 6.5px;
    --line-height: 1.1875;
    --selct-bg-color: transparent;
    align-self: center;
    --dropdown-border: 1px solid var(--light-gray);
    width: 58px;
    .choices {
      --fz: 16px;
      --font-weight: 500;
      &__inner {
        min-height: 0;
      }
      &__list--dropdown {
        border: var(--dropdown-border) !important;

        left: auto;
        right: 0;
      }

      &[data-type*='select-one']:after,
      &[data-type*='select-multiple']:after {
        right: 9px;
        width: 13.34px;
        height: 7.67px;
        margin-top: -3px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66929 5.25529L11.6193 0.305288C11.7115 0.209778 11.8219 0.133596 11.9439 0.0811868C12.0659 0.0287778 12.1971 0.00119157 12.3299 3.77567e-05C12.4627 -0.00111606 12.5943 0.0241857 12.7172 0.0744665C12.8401 0.124747 12.9518 0.199001 13.0457 0.292893C13.1396 0.386786 13.2138 0.498438 13.2641 0.621334C13.3144 0.744231 13.3397 0.87591 13.3385 1.00869C13.3374 1.14147 13.3098 1.27269 13.2574 1.39469C13.205 1.5167 13.1288 1.62704 13.0333 1.71929L7.37629 7.37629C7.18876 7.56376 6.93445 7.66907 6.66929 7.66907C6.40412 7.66907 6.14982 7.56376 5.96229 7.37629L0.305288 1.71929C0.209778 1.62704 0.133596 1.5167 0.0811868 1.39469C0.0287778 1.27269 0.00119157 1.14147 3.77568e-05 1.00869C-0.00111606 0.87591 0.0241856 0.744231 0.0744665 0.621334C0.124747 0.498438 0.199001 0.386786 0.292893 0.292893C0.386786 0.199001 0.498438 0.124747 0.621334 0.0744665C0.744231 0.0241857 0.87591 -0.00111606 1.00869 3.77567e-05C1.14147 0.00119157 1.27269 0.0287778 1.39469 0.0811868C1.5167 0.133596 1.62704 0.209778 1.71929 0.305288L6.66929 5.25529Z' fill='%23888996'/%3E%3C/svg%3E%0A");
      }
    }
  }

  label {
    font-family: var(--font-main);
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 700;
    color: var(--black);
    cursor: pointer;
    width: 100%;
  }

  select {
    opacity: 0;
    height: ac(50px, 48px);
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;
    border-radius: 5px;

    height: 100%;

    &__inner {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 0 !important;
      border-radius: var(--select-border-radius);
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      cursor: pointer;
      font-size: ac(16px, 14px);
      line-height: var(--line-height);
      font-weight: 500;
      font-family: var(--font-main);
      color: var(--black);
      box-shadow: none;

      &.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }
    }

    &__list--single {
      color: var(--text-color);
    }

    &.is-open {
      &:not(.is-flipped) {
        .choices__inner {
          border-radius: var(--select-border-radius);
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          border-top: none;
          margin-top: 0;
          border-radius: var(--select-border-radius);
        }
      }

      &.is-flipped {
        .choices__inner {
          border-radius: 0 0 6px 6px;
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 0;
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        }
      }
    }

    &__item {
      max-width: 100%;
    }

    &__placeholder {
      color: var(--navy);
      opacity: 1;
    }

    &__input {
      border: 1px solid var(--border-color) !important;
      border-radius: var(--select-border-radius) !important;
    }

    &__list {
      padding: 0;

      .choices__item {
        font-size: var(--fz);
        font-family: var(--font-main);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        padding: var(--select-padding);
        transition: 0.3s color;

        &.is-selected {
          color: var(--list-item-selected-color);
        }
      }

      &--single {
        .choices__item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: var(--max-width);
        }
      }

      &--multiple {
        color: var(--black);

        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        border: var(--dropdown-border) !important;
        background-color: var(--dropdown-bg-color);

        .choices__item {
          color: var(--text-color);
          border-bottom: 1px solid var(--list-border-color);
          padding: var(--dropdown-item-padding);
        }
      }

      &--single {
        background: var(--selct-bg-color);
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;

      &:after {
        border: none;
        content: '';
        display: block;
        width: 14.4px;
        height: 8px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 18px;
        top: 50%;
        transition: all 0.15s ease-in-out;
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.234315 0.234315C0.546734 -0.0781049 1.05327 -0.0781049 1.36569 0.234315L7.2 6.06863L13.0343 0.234315C13.3467 -0.0781049 13.8533 -0.0781049 14.1657 0.234315C14.4781 0.546734 14.4781 1.05327 14.1657 1.36569L7.76569 7.76569C7.45327 8.07811 6.94673 8.07811 6.63431 7.76569L0.234315 1.36569C-0.0781048 1.05327 -0.0781048 0.546734 0.234315 0.234315Z' fill='%23061344'/%3E%3C/svg%3E%0A");
      }
    }

    &.is-open {
      &:after {
        transform: rotateX(180deg);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: var(--hover-item-bg);

      &:not(.is-selected) {
        color: var(--hover-item-color);
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      z-index: 20;
      border-radius: 6px;
      max-width: 100%;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 2px solid var(--cl-grey);
      border-radius: 8px;
      background: var(--transparent);
      color: var(--cl-dark-grey);
      font-size: var(--fz);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--navy);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease,
        transform 0.25s ease;
      opacity: 0.5;
      background: var(--navy);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }
}

.meta-select-nova {
  position: relative;

  div[role='status'] {
    position: absolute;
    top: 24px;
    left: 0;
    bottom: 0;
    z-index: 50;
    height: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s;

    svg {
      fill: lightgray;
      color: var(--mid-gray);
    }
  }

  &.is-loading {
    pointer-events: none;
    div[role='status'] {
      opacity: 1;
    }

    .choices {
      &__inner {
        background-color: #eaeaea !important;
        border-color: var(--mid-gray) !important;
      }

      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        border-color: var(--mid-gray) !important;
      }

      &__item--selectable {
        background-color: #eaeaea !important;

        &.choices__placeholder {
          color: var(--mid-gray) !important;
        }
      }

      &__item {
        color: var(--mid-gray) !important;
      }

      &__list {
        background-color: #eaeaea !important;

        &--dropdown {
          border-color: var(--mid-gray) !important;
        }
      }

      &[data-type*='select-one'] .choices__input {
        border-color: var(--mid-gray) !important;
      }
    }
  }

  .meta-select {
    width: 100%;
  }
}

/*enf of meta select*/
