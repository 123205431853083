.btn {
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  min-height: 48px;
  padding: 10px 24px 7px;
  color: var(--primary-green);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;
  border-radius: 36px;
  transition: 0.3s;
  flex-shrink: 0;

  &:hover {
    transform: translateY(-1px);
    background: var(--primary-green);
    color: var(--white);
  }

  i {
    display: inline-flex;
  }

  &.btn-primary {
    background: var(--primary-green);
    position: relative;
    color: var(--white);
    gap: 10px;
    min-height: 52px;

    i {
      padding-bottom: ac(5px, 2px);
    }

    &::before {
      content: '';
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
      border: 2px solid var(--light-gray);
      border-radius: inherit;
      pointer-events: none;
    }

    span {
      position: relative;
      z-index: 1;
      font-size: inherit;
    }

    i {
      display: inline-flex;
    }

    &:hover {
      background: var(--white);
      color: var(--primary-green);
    }
  }

  &.btn-border-green {
    position: relative;
    background: var(--white);
    color: var(--primary-green);
    border: 1px solid var(--primary-green);
    gap: 8px;
    min-height: 50px;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      left: 1px;
      top: 1px;
      border: 2px solid var(--light-gray);
      border-radius: inherit;
      pointer-events: none;
    }

    span {
      position: relative;
      z-index: 1;
    }

    i {
      display: inline-flex;
    }

    &:hover {
      background: var(--primary-green);
      color: var(--white);
    }
  }

  &.btn-green {
    border-color: var(--primary-green);
    background: var(--primary-green);
    color: var(--white);
    padding: 5px 16px 2px 24px;
    gap: 10px;

    i {
      transform: translateY(-9%);
    }

    &:hover {
      background: var(--white);
      color: var(--primary-green);
    }
  }

}

.icon-links {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
}

.icon-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--primary-green);
  font-size: 20px;
  transition: 0.3s;
  line-height: 1;
  position: relative;
  cursor: pointer;

  &:hover, &.active {
    color: var(--white);
    background: var(--primary-green);
  }
  div{
    font-size: 14px;
    font-weight: 600;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 2px 3px;
    position: absolute;
    right: 0;
    top: 0;
    background: #EA4434;
    transform: translate(30%, -30%);
    opacity: 0;
    pointer-events: none;
    span{
      @mixin max-line-length-one;
      max-width: 15px;
    }


  }
  &.active{
    div{
      opacity: 1;
      pointer-events: all;
    }
    &:hover{
      background: var(--light-harvest);
      color: var(--primary-green);
      box-shadow: 0 0 0 1px var(--primary-green);
    }
    span{
      color: var(--white);
    }
  }


}

.callback-form {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--primary-green);
  border-radius: 1000px;
  padding: 6px;
  padding-left: 24px;
  background: var(--light-bg);

  .pre-label {
    color: var(--primary-green);
  }

  input {
    border: 0;
    box-shadow: none;
    flex: 1;
    flex-shrink: 0;
    width: auto;
    color: var(--black);
    padding: 0;
    font-size: 16px;
    font-weight: 500;

    &::placeholder {
      opacity: 1;
    }
  }
}

.search-circle-btn {
  display: flex;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-green);
  border: 1px solid var(--primary-green);
  color: var(--white);
  transition: 0.3s;
  cursor: pointer;
  font-size: 19px;

  &:hover {
    background: var(--white);
    color: var(--primary-green);
  }
}

.remove-btn{
  color: var(--red);
  transition: 0.3s;
  cursor: pointer;
  will-change: transform, opacity;
  line-height: 1;
  font-size: ac(22px, 20px);
  &:hover{
    opacity: 0.8;
    transform: scale(1.1);
  }
}
.edit-btn{
  color: var(--primary-green);
  width: fit-content;
  cursor: pointer;
  transition: 0.3s;
  font-size: ac(18px, 16px);
  font-weight: 600;
  gap: ac(20px, 10px);
  display: inline-flex;
  align-items: center;
  line-height: 1.222;
  i{
    display: inline-block;
    transform: translateY(-14%);
  }
  &:hover{
    color: var(--black);
  }
}