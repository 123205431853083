.hero-product {
  padding: 55px 0 ac(62px, 35px);
  z-index: 1;

  &-wrapper {
    display: flex;
    gap: ac(48px, 20px);
    align-items: flex-start;
  }
}

.hero-product-swiper-wr {
  flex: 1;
  flex-shrink: 0;
  width: ac(698px, 358px);
  display: flex;
  gap: 16px;
  align-items: center;

  .hero-product-360-btn {
    position: absolute;
    top: ac(30px, 20px);
    right: ac(30px, 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    --size: ac(54px, 40px);
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--primary-green);
    /*background: rgba(255, 255, 255, 0.8);*/
    color: var(--black);
    font-size: ac(32px, 24px);
    transition: 0.3s;
    background: rgba(255, 255, 255, 0.8);
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      color: var(--primary-green);
    }

    @media (max-width: 451px) {
      right: 0;
      top: 20px;
    }
  }

  .swiper-thumbs-wr {
    flex-shrink: 0;
    width: ac(160px, 70px);
    height: ac(544px, 334px);
    position: relative;
    display: flex;
    align-items: center;

    .swiper {
      height: ac(160px, 70px);
      overflow: visible;

      &-buttons {
        position: static;
      }

      &-button {
        position: absolute;
        left: 50% !important;
        transform: translateX(-50%) rotate(90deg);

        &.swiper-button-prev {
          top: 0;
        }

        &.swiper-button-next {
          bottom: 0;
        }
      }

      &-slide {
        overflow: visible;
        position: relative;
        padding-top: 100%;
        border-radius: 6px;

        &.swiper-slide-active {
          .product-thumb-item {
            border-radius: 12px;
            box-shadow: 0 0 8 0 rgba(0, 0, 0, 0.24);

            &::before {
              opacity: 0.5;
            }

            &-icon {
              opacity: 1;
            }
          }
        }
      }
    }

    .product-thumb-item {
      cursor: pointer;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0);
    }
  }

  .swiper-main {
    width: 50%;
    flex: 1 0 auto;
    overflow: visible;
    pointer-events: none;

    .swiper-slide {
      overflow: visible;
    }

    .product-thumb-item {
      border-radius: 12px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
      position: relative;
      height: auto;
      width: 100%;
      pointer-events: all;

      &::before {
        position: relative;
        height: auto;
        padding-top: 100%;
      }
    }

    .product-thumb-item-360 {
      border-radius: 12px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
      position: relative;
      height: auto;
      width: 100%;
      pointer-events: all;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transform: translate(0);

      &::before {
        position: relative;
        height: auto;
        padding-top: 100%;
        content: '';
      }

      > div {
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        user-select: none;

        .product-viewer__image {
          user-select: none;
        }
      }

      .cursor-aura {
        position: fixed;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.7);
        /*background: radial-gradient(
          var(--red),
          color-mod(var(--red) a(0%)) 40%
        );*/
        animation: pulse 1.23s infinite;
        animation-delay: 0.35s;
        width: ac(80px, 60px);
        height: ac(80px, 60px);
        margin-top: ac(-40px, -30px);
        margin-left: ac(-40px, -30px);
        border-radius: 50%;
        transition: transform 0.08s, opacity 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        pointer-events: none;
        z-index: 1;
        transform: translate(-50%, -50%);

        svg {
          --size: 70%;
          width: var(--size);
          height: var(--size);
          animation: rotate360 4s linear infinite;

          path {
            transition: 0.3s;
            fill: var(--primary-green);
          }
        }

        &.active {
          opacity: 1;
        }

        @media (max-width: 1024px) {
          display: none;
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(242, 204, 215, 0.5);
          }
          70% {
            box-shadow: 0 0 0 6px rgba(242, 204, 215, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(242, 204, 215, 0);
          }
        }

        @keyframes rotate360 {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

.product-thumb-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  transition: 0.3s;
  transform: translate(0);

  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: var(--primary-green);
    opacity: 0;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }

  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(22px, 18px);
    width: ac(50px, 40px);
    height: ac(50px, 40px);
    border: 1px solid var(--white);
    background: rgba(255, 255, 255, 0.8);
    color: var(--black);
    z-index: 3;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    .product-thumb-item-icon {
      opacity: 1;
    }
  }
}

.hero-product {
  &-order-box {
    flex: 1;
  }

  .title-wr {
    padding-bottom: 19px;
    border-bottom: 1px solid var(--light-gray);
  }

  .title {
    margin-bottom: 24px;
  }

  &-ready {
    color: var(--primary-green);
    width: 100%;
    font-size: ac(20px, 18px);
    font-weight: 600;
    line-height: 1.2;
  }

  &-price {
    padding-top: 17px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 12px;

    &-amount {
      display: flex;
      align-items: center;
      gap: 10px;

      div {
        font-size: ac(36px, 22px);
        font-weight: 600;

        b {
          color: var(--secondary-green);
          font-weight: inherit;
          font-size: 77.77%;
          padding-left: 6px;
        }
      }

      span {
        font-size: ac(20px, 16px);
        color: var(--dark-gray);
        padding-top: ac(8px, 2px);
        font-weight: 600;

        b {
          padding-right: 10px;
        }
      }
    }

    .goods-card-discount {
      position: static;
      transform: none;
      margin-bottom: ac(7px, 0px);
    }
  }

  &-pack {
    display: flex;
    color: var(--dark-gray);
    font-weight: 600;
    font-size: ac(20px, 18px);
    padding-bottom: ac(35px, 25px);

    b {
      color: var(--black);
      font-weight: 600;
      padding-left: ac(13px, 5px);
    }
  }

  &-form {
    &-wrapper {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 14px;
    }

    .btn {
      padding: 10px 16px 8px 24px;
      gap: 8px !important;

      i {
        display: inline-flex;
        font-size: 128%;
      }
    }
  }
}

.meta-select-product.meta-select {
  flex: 1;

  & > label {
    color: var(--dark-gray);
    font-size: 14px;
    display: block;
    margin-bottom: 7px;
    font-weight: 500;
  }

  .choices {
    &__inner {
      --selct-bg-color: var(--light-bg);
    }
  }
}

.product-order {
  &-controls {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: ac(124px, 110px);
    width: fit-content;
    gap: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-poppins);
    border: 1px solid var(--light-gray);
    border-radius: 1000px;
    background: var(--light-bg);

    input {
      width: 36px;
      max-width: 36px;
      background: transparent;
      color: var(--black);
      height: 20px;
      max-height: 20px;
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-poppins);
      text-align: center;
    }
  }

  &-btn {
    position: relative;
    width: ac(40px, 30px);
    height: ac(40px, 30px);
    display: block;
    cursor: pointer;
    transition: 0.3s;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid var(--secondary-green);
    background: var(--white);

    &:active {
      transform: scale(0.95);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      background: var(--secondary-green);

      &::before,
      &::after {
        background: var(--white);
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 2px;
      border-radius: 2px;
      background: var(--black);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      height: 15px;
      width: 2px;
    }

    &.minus {
      margin-left: -1px;

      &::after {
        display: none;
      }
    }

    &.plus {
      margin-right: -1px;
    }
  }

  &-qty {
    flex: 1;
    text-align: center;
  }
}

.product-info-section {
  padding: 64px 0 48px;
  background: var(--light-bg);
  overflow: hidden;

  .cont {
    position: relative;
  }

  .bg-layer {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(14%, 40.4%) scale(0.5);
    width: 447px;
    max-width: 40%;
    opacity: 0;
    &.animated {
      transform: translate(14%, 40.4%) scale(1);
      opacity: 0.2;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .content-elements {
    padding-bottom: 40px;
    font-size: ac(18px, 16px);
    font-weight: 400;
    line-height: 1.44;

    h1 {
      font-size: ac(42px, 33px);
      margin-bottom: 23px;
    }
    h2 {
      font-size: ac(32px, 28px);
      margin-bottom: 23px;
    }
    h3 {
      font-size: ac(30px, 24px);
    }
    h4,
    h5,
    h6 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    a {
      color: var(--primary-green);
      display: inline;
    }
    p:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}

.product-info-tabs {
  display: flex;
  padding-bottom: 40px;

  &-item {
    --px: 19px;
    border: 1px solid var(--light-gray);
    border-radius: 1000px;
    padding: 11px var(--px) 10px;
    color: var(--white);
    background: var(--secondary-green);
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    min-height: ac(57px, 44px);
    display: flex;
    align-items: center;
    font-weight: 600;

    &.active {
      background: var(--primary-green);
    }

    &:hover {
      background: var(--white);
      border-color: var(--primary-green);
      color: var(--primary-green);
    }

    &:not(:first-child) {
      margin-left: -50px;
      padding-left: calc(50px + var(--px));
    }

    &:nth-of-type(1) {
      z-index: 10;
    }

    &:nth-of-type(2) {
      z-index: 9;
    }

    &:nth-of-type(3) {
      z-index: 8;
    }

    &:nth-of-type(4) {
      z-index: 7;
    }
  }
}

.product-info-card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.product-info-swiper-wr {
  max-width: ac(844px, 500px);
  width: 100%;

  .swiper {
    overflow: visible;

    &-slide {
      overflow: visible;
      .product-info-card {
        opacity: 0;
        transition: 0.5s;
      }
      &-active {
        .product-info-card {
          opacity: 1;
        }
      }
    }
  }
}
.goods-section.goods-section-similar {
  padding-bottom: 0;
}

.goods-swiper-wr {
  .swiper {
    overflow: visible;

    &-slide {
      width: 304px;
      max-width: 100%;
    }
  }
  .btn {
    margin-top: ac(55px, 40px);
  }
}

.product-review-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-row-gap: ac(20px, 16px);

  &:not(:last-child) {
    margin-bottom: ac(50px, 24px);
  }

  + .pagination-wrapper {
    .pagination-item {
      &:not(:hover) {
        background-color: transparent;
      }
    }
  }
}

.product-review-item {
  width: 100%;

  .content-elements {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    padding-bottom: ac(20px, 16px);
    border-bottom: 1px solid var(--light-gray);
  }
}

.product-review-item-bottom {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ac(16px, 14px);

  .product-review-item-image {
    @mixin aspect-ratio 1, 1;
    width: ac(80px, 60px);
    min-width: ac(80px, 60px);
    border-radius: 50%;

    img {
      object-position: top;
    }
  }

  .product-review-item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(10px, 8px);

    h3 {
      font-size: ac(24px, 20px);
    }

    h4 {
      font-size: ac(20px, 16px);
      color: var(--primary-green);
    }
  }
}

@media (max-width: 750px) {
  .hero-product {
    &-wrapper {
      flex-direction: column;
    }
    &-swiper-wr {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 650px) {
  .product-info-tabs {
    flex-direction: column;
    &-item {
      margin-left: 0 !important;
      --mt: 40px;
      padding: calc(var(--mt) + 20px) 20px 20px !important;
      text-align: center;
      width: 100%;
      margin-top: calc(var(--mt) * -1);
      border-radius: 12px;
      justify-content: center;
      &:first-child {
        padding-top: 25px !important;
        margin-top: 0;
      }
    }
  }
}
