.footer {
  padding: 48px 0 32px;
  &-logo {
    width: ac(304px, 150px, 1200);
    flex-shrink: 0;
    transition: 0.3s;
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      transform: scale(1.01);
    }
  }
  &-main {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    border-bottom: 2px solid var(--light-gray);
    padding-bottom: 32px;
  }
  .callback-form {
    width: 100%;
    max-width: 518px;
    align-self: flex-end;
    margin-bottom: 62px;
    &-input-wr {
      position: relative;
      flex: 1;
      align-items: center;
      display: flex;
      margin-top: 2px;
      gap: 2px;
      font-weight: 500;
    }
    .btn {
      min-height: 43px;
    }
    input {
      background: transparent;
      width: 100%;
    }
  }

  &-navbar {
    flex: 1;
    max-width: 862px;
    display: flex;
    flex-direction: column;
    padding-top: ac(48px, 0px);
    a {
      font-weight: 500;
    }

    &-nav {
      display: flex;
      gap: ac(42px, 24px, 1300);
    }
    &-menu {
      h3 {
        font-size: ac(20px, 16px);
        line-height: 1.05;
        font-weight: 600;
        color: var(--primary-green);
        font-family: var(--font-main);
        margin-bottom: 22px;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 7.3px 9px;
      }
      &.category {
        flex: 1;

        ul {
          grid-template-columns: repeat(4, 1fr);

          li {
            line-height: 1.2;
            letter-spacing: -0.02em;

            @media (min-width: 1301px) {
              margin-bottom: 14px;
            }

            @media (max-width: 750px) {
              display: flex;
              justify-content: center;
              align-items: center;

              a {
                text-align: center;
              }
            }
          }

          @media (min-width: 1301px) {
            display: block;
            column-count: 3;
            grid-column-gap: ac(36px, 20px);
          }
        }

        &.one-column {
          ul {
            @media (min-width: 1301px) {
              column-count: 1;
            }
          }
        }

        &.two-column {
          ul {
            @media (min-width: 1301px) {
              column-count: 2;
            }
          }
        }
      }
    }

    a {
      transition: 0.3s;
      &:hover {
        color: var(--secondary-green);
      }
    }
  }

  &-swiper-wr {
    width: 100%;
    max-width: 100%;
    position: relative;

    .heading-4 {
      margin-bottom: ac(35px, 26px);
      text-align: right;

      @media (max-width: 1300px) {
        text-align: center;
      }
    }

    .swiper {
      width: 100%;
      max-width: 100%;
    }

    .swiper-buttons {
      @media (min-width: 1301px) {
        position: absolute;
        top: -16px;
        right: 0;
        transform: translateY(-100%);
      }

      @media (max-width: 1300px) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ac(32px, 24px);
      }
    }

    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .meta-checkbox {
        margin-top: -40px;
        max-width: 518px;
        margin-bottom: ac(32px, 24px);
        width: auto;
        margin-left: auto;
        position: relative;
        z-index: 10;

        @media (max-width: 1300px) {
          margin-right: auto;
          margin-top: -20px;
        }
      }
    }

    .callback-form {
      width: 100%;
      min-height: 57px;

      @media (max-width: 1300px) {
        margin-bottom: 32px !important;
      }
    }
  }

  &-bottom {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    p {
      color: var(--dark-gray);
      font-size: 14px;
      line-height: 1.4;
    }

    position: relative;

    #metamorfosi-credits {
      width: 150px;
      margin-top: 12px;

      img {
        transition: 0.3s;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      @media (min-width: 769px) {
        margin-top: 0;
        position: absolute;
        left: 50%;
        top: calc(30px + 50%);
        transform: translateX(-50%) translateY(calc(-15px + -50%));
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

@media (max-width: 1300px) {
  .footer {
    &-main {
      align-items: center;
      flex-direction: column;
    }
    .callback-form {
      margin: 0 auto 45px;
    }
    &-navbar {
      padding-top: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    &-navbar {
      &-nav {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      &-menu {
        ul {
          flex-wrap: wrap;
        }
        &.category {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .footer-navbar-menu.category ul {
    grid-template-columns: 1fr 1fr;
    text-align: left;
    max-width: 320px;
    margin: 0 auto;
  }
}

@media (max-width: 550px) {
  .footer {
    .callback-form {
      flex-direction: column;
      border-radius: 12px;
      padding: 35px 12px 30px;
      width: 375px;
      max-width: 100%;
      margin: 0 auto 35px;
      &-input-wr {
        width: 100%;
        border-radius: 36px;
        background: var(--white);
        min-height: 48px;
        padding: 8px 20px 5px;
        margin-bottom: 25px;
        line-height: 1;
        border: 1px solid var(--light-gray);
        position: relative;
        font-size: 16px;
      }

      input {
        width: 100%;
      }
      .pre-label {
      }
    }
    &-bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  .iphone-support {
    .callback-form .pre-label {
      padding-bottom: 1px;
    }
  }
}
@media (max-width: 450px) {
  .footer-navbar {
    &-menu {
      ul {
        /*grid-template-columns: 1fr!important;*/
      }
    }
  }
}

@media (max-width: 375px) {
}
