@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,500;1,500&display=swap');

@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts/fixeltext-medium.woff2') format('woff2'),
  url('../../assets/fonts/fixel-fonts/fixeltext-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts/fixeltext-regular.woff2') format('woff2'),
  url('../../assets/fonts/fixel-fonts/fixeltext-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts/fixeltext-semibold.woff2') format('woff2'),
  url('../../assets/fonts/fixel-fonts/fixeltext-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
/*@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts-ng/fixeltext-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts-ng/fixeltext-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Fixel';
  src: url('../../assets/fonts/fixel-fonts-ng/fixeltext-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}*/

:root {
  --primary-green: #406B2D;
  --secondary-green: #6B824C;
  --black: #242542;
  --dark-gray: #686976;
  --mid-gray: #888996;
  --light-gray: #DFDFE3;
  --light-bg: #F5F6F6;







  --light-harvest: #FFFCF2;
  --white: #ffffff;
  --yellow: #fde28a;
  --red: #F84F4B;

  --font-main: 'Fixel', sans-serif;
  --font-poppins: 'Poppins', sans-serif;
  --font-philosopher: 'Philosopher', sans-serif;
  --font-inter: "Inter Tight", sans-serif;

  --fz: 16px;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 992;

  --header-height: ac(144px, 104px, 375);

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */



@define-mixin mobile-menu-start-point {
  @media (max-width: 992px){
    @mixin-content;
  }
}
@define-mixin mobile-menu-end-point {
  @media (min-width: 993px) {
    @mixin-content;
  }
}

