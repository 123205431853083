@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot?m1evbf');
  src: url('../../assets/fonts/icomoon.eot?m1evbf#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?m1evbf') format('truetype'),
    url('../../assets/fonts/icomoon.woff?m1evbf') format('woff'),
    url('../../assets/fonts/icomoon.svg?m1evbf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-360:before {
  content: '\e919';
}
.icon-edit:before {
  content: '\e918';
}
.icon-remove-btn:before {
  content: '\e917';
}
.icon-calendar:before {
  content: '\e916';
}
.icon-phone-thin:before {
  content: '\e90c';
}
.icon-location:before {
  content: '\e915';
}
.icon-save-full:before {
  content: '\e913';
}
.icon-instagram:before {
  content: '\e900';
}
.icon-arrow-right:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e914';
}
.icon-basket:before {
  content: '\e902';
}
.icon-chevron-down:before {
  content: '\e903';
}
.icon-chevron-up:before {
  content: '\e904';
}
.icon-chevron-left:before {
  content: '\e905';
}
.icon-chevron-right:before {
  content: '\e906';
}
.icon-email:before {
  padding-bottom: ac(2px, 0px);
  &::before {
    content: '\e907';
  }
}
.icon-eye:before {
  content: '\e908';
}
.icon-facebook:before {
  padding-bottom: ac(2px, 0px);
  &::before {
    content: '\e909';
  }
}
.icon-heart:before {
  content: '\e90a';
}
.icon-label:before {
  content: '\e90b';
}
.icon-phone:before {
  content: '\e90d';
}
.icon-plane:before {
  content: '\e90e';
}
.icon-search:before {
  content: '\e90f';
}
.icon-tiktok:before {
  content: '\e910';
}
.icon-twitter_x:before {
  padding-bottom: ac(2px, 0px);
  &::before {
    content: '\e911';
  }
}
.icon-user:before {
  content: '\e912';
}
