.search-products-page {
  @media (min-width: 751px) {
    overflow: visible !important;
    .header .navbar:before {
      width: 99vw;
    }
  }
}

.product-search-section {
  padding: 66px 0 82px;

  .title {
    margin-bottom: 5px;
  }
}

.product-search {
  display: flex;
  gap: ac(40px, 20px, 1100);

  &-aside {
    flex-shrink: 0;
    width: ac(284px, 200px, 1100);

    h3 {
      font-size: ac(32px, 22px);
      font-weight: 700;
      line-height: 1.125;
      margin-bottom: 24px;
    }
  }

  &-filters {
    position: sticky;
    top: 40px;
    display: flex;
    flex-direction: column;

    &-group {
      h4 {
        font-size: ac(22px, 18px);
        font-weight: 600;
        font-family: var(--font-main);
        line-height: 1.2727;
        margin-bottom: 26px;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        padding-left: 2px;
      }

      .checkbox-wr {
        .checkbox-text {
          transition: 0.3s;
        }

        &:hover {
          .checkbox-icon {
            border-color: var(--primary-green);
          }

          .checkbox-text {
            color: var(--primary-green);
          }
        }
      }
    }
  }

  &-input-wr {
    position: relative;
    margin-bottom: 40px;

    input {
      width: 100%;
      height: 56px;
      line-height: 1;
      padding: 22px 64px 22px 20px;
      border-radius: 1000px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 0 0 1px var(--secondary-green);
    }

    .search-circle-btn {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &-results {
    flex: 1;
    padding-top: 22px;

    &-info {
      display: flex;
      gap: 35px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 38px;
    }

    &-count {
      padding-bottom: 2px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      font-size: ac(18px, 16px);
      line-height: 1.222;
      font-weight: 500;
      align-items: center;

      b {
        font-weight: 600;
        color: var(--primary-green);
        font-size: 20px;
        /*line-height: 1.2;*/
      }
    }

    .meta-select {
      flex-shrink: 0;

      .choices {
        width: 196px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 38.6px 20px;
      padding-bottom: 64px;
    }
  }
}

@media (max-width: 992px) {
  .product-search-results-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 750px) {
  .product-search {
    flex-direction: column;
    &-aside {
      width: 100%;
    }
    &-filters-group {
      ul {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px 20px;
      }
    }
  }
}

@media (max-width: 650px) {
  .product-search-results-grid {
    grid-template-columns: 1fr;
    gap: 25px;
  }
}
