.cart-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    overflow: hidden;
   /* display: flex;*/
    justify-content: flex-end;
    display: none;

    &-bg{
        position: absolute;
        inset: 0;
        background: rgba(0,0,0,0.2);
        z-index: 0;
    }
    &-container{
        width: 486px!important;
        max-width: 100%;
        background: var(--white);
        border-left: 1px solid var(--light-gray);
        box-shadow: -16px 0 60px 0 rgba(0,0,0,0.25);
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        transform: translateX(100%);
        transition: 0.5s;
    }
    &-header{
        padding: 19px 40px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background: var(--light-harvest);
        border-bottom: 1px solid var(--light-gray);
        h2{
            font-size: 32px;
            font-weight: 700;
            line-height: 1.125;
        }
        .icon-link{
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            transform: translateY(10%);
        }
    }
    &-products-wrapper{
        flex: 1;
        overflow: hidden;
        display: flex;
        padding: 4px ac(22px, 10px) 0;

    }
    &-products{
        width: 100%;
        .simplebar-track.simplebar-vertical{

        }
    }
    &-footer{
        padding: 12px ac(24px, 10px) ac(30px, 20px);
        border-top: 1px solid var(--light-gray);
        .cart-total-controls{
            flex-direction: column;
            & > div{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

            }
            p{
                font-size: ac(24px, 18px);
                font-weight: 600;
                line-height: 1.166;
            }
            .btn{
                justify-content: center;
                margin-bottom: 0;
            }
        }
    }
    .cart-products-item{
        padding: ac(21px, 15px) 5px ac(23px, 18px) 1px;
        gap: ac(20px, 10px);

        &::before{
            left: 9px;
            width: calc( 100% - 9px);
        }

        &-thumbnail{
            width: 96px;
            border-radius: 8px;

            &::before{
                padding-top: 100%;
            }
        }

        &-inner{
            gap: ac(10px, 5px);
        }
        .remove-btn{
            margin-top: 0;
            transform: translateY(ac(9px, 0px));
            color: var(--black);
        }

        &:last-of-type{

        }
        &:hover{
            .remove-btn{
                color: var(--red);
            }
        }
        &-price{
            gap: 2px;
            b{
                transform: translateY(ac(1px, 0px, 375));
            }
        }
    }
    &.active{
        .cart-popup-container{
            transform: none;
        }
    }
    @media (max-width: 550px) {
        .cart-products-item-main{
            flex-wrap: nowrap;
        }
    }
    @media (max-width: 450px) {
        .cart-products-item {
            &-main{
                padding-bottom: 20px;
            }
            &-footer {
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }

        }
    }
}



